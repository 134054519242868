import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2bca6bfd = () => interopDefault(import('../pages/campaign/index.vue' /* webpackChunkName: "pages/campaign/index" */))
const _a64e4c90 = () => interopDefault(import('../pages/chatbot/index.vue' /* webpackChunkName: "pages/chatbot/index" */))
const _d9025900 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _67558f4f = () => interopDefault(import('../pages/contacts-upload-multiple/index.vue' /* webpackChunkName: "pages/contacts-upload-multiple/index" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _372bc5c0 = () => interopDefault(import('../pages/direct_login/index.vue' /* webpackChunkName: "pages/direct_login/index" */))
const _56caef86 = () => interopDefault(import('../pages/finances/index.vue' /* webpackChunkName: "pages/finances/index" */))
const _62708680 = () => interopDefault(import('../pages/forgot_password/index.vue' /* webpackChunkName: "pages/forgot_password/index" */))
const _01ade38f = () => interopDefault(import('../pages/inbox/index.vue' /* webpackChunkName: "pages/inbox/index" */))
const _20d0f04c = () => interopDefault(import('../pages/integrations/index.vue' /* webpackChunkName: "pages/integrations/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _97d2aa8a = () => interopDefault(import('../pages/outlookcallback/index.vue' /* webpackChunkName: "pages/outlookcallback/index" */))
const _16617c08 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _24927f38 = () => interopDefault(import('../pages/reset_password/index.vue' /* webpackChunkName: "pages/reset_password/index" */))
const _72065d6e = () => interopDefault(import('../pages/resources/index.vue' /* webpackChunkName: "pages/resources/index" */))
const _2c20a960 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _203e1008 = () => interopDefault(import('../pages/shopeecallback/index.vue' /* webpackChunkName: "pages/shopeecallback/index" */))
const _78085d26 = () => interopDefault(import('../pages/sign_up/index.vue' /* webpackChunkName: "pages/sign_up/index" */))
const _5e6fd470 = () => interopDefault(import('../pages/sso-callback/index.vue' /* webpackChunkName: "pages/sso-callback/index" */))
const _517f8219 = () => interopDefault(import('../pages/unprivileged/index.vue' /* webpackChunkName: "pages/unprivileged/index" */))
const _6cf499ff = () => interopDefault(import('../pages/whatsapp/index.vue' /* webpackChunkName: "pages/whatsapp/index" */))
const _392eae57 = () => interopDefault(import('../pages/finances/ask_airene/index.vue' /* webpackChunkName: "pages/finances/ask_airene/index" */))
const _a9172878 = () => interopDefault(import('../pages/finances/billable_usages.vue' /* webpackChunkName: "pages/finances/billable_usages" */))
const _2954d961 = () => interopDefault(import('../pages/finances/billing_usages.copy.vue' /* webpackChunkName: "pages/finances/billing_usages.copy" */))
const _433edec8 = () => interopDefault(import('../pages/finances/chatbot_usage/index.vue' /* webpackChunkName: "pages/finances/chatbot_usage/index" */))
const _a4b4ff64 = () => interopDefault(import('../pages/finances/credits.vue' /* webpackChunkName: "pages/finances/credits" */))
const _3c1e803c = () => interopDefault(import('../pages/finances/invoices.vue' /* webpackChunkName: "pages/finances/invoices" */))
const _f2681d3e = () => interopDefault(import('../pages/finances/orders.vue' /* webpackChunkName: "pages/finances/orders" */))
const _342e781c = () => interopDefault(import('../pages/finances/package_usage.vue' /* webpackChunkName: "pages/finances/package_usage" */))
const _07099eae = () => interopDefault(import('../pages/finances/payments.vue' /* webpackChunkName: "pages/finances/payments" */))
const _07ed4a0c = () => interopDefault(import('../pages/finances/subscribe_plan.vue' /* webpackChunkName: "pages/finances/subscribe_plan" */))
const _99771338 = () => interopDefault(import('../pages/finances/topup.vue' /* webpackChunkName: "pages/finances/topup" */))
const _3ba1ae86 = () => interopDefault(import('../pages/integrations/ecommerce/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/index" */))
const _188687ce = () => interopDefault(import('../pages/integrations/email/index.vue' /* webpackChunkName: "pages/integrations/email/index" */))
const _ece2fb5a = () => interopDefault(import('../pages/integrations/facebook_messenger/index.vue' /* webpackChunkName: "pages/integrations/facebook_messenger/index" */))
const _1df6d3af = () => interopDefault(import('../pages/integrations/instagram/index.vue' /* webpackChunkName: "pages/integrations/instagram/index" */))
const _88b158fe = () => interopDefault(import('../pages/integrations/line_messenger/index.vue' /* webpackChunkName: "pages/integrations/line_messenger/index" */))
const _3156b30c = () => interopDefault(import('../pages/integrations/livechatcom/index.vue' /* webpackChunkName: "pages/integrations/livechatcom/index" */))
const _13ff0a73 = () => interopDefault(import('../pages/integrations/mobilechat/index.vue' /* webpackChunkName: "pages/integrations/mobilechat/index" */))
const _4b39927f = () => interopDefault(import('../pages/integrations/qiscus/index.vue' /* webpackChunkName: "pages/integrations/qiscus/index" */))
const _0b0e4d66 = () => interopDefault(import('../pages/integrations/qontak_crm/index.vue' /* webpackChunkName: "pages/integrations/qontak_crm/index" */))
const _abd414e2 = () => interopDefault(import('../pages/integrations/shopee/index.vue' /* webpackChunkName: "pages/integrations/shopee/index" */))
const _2c8ff33a = () => interopDefault(import('../pages/integrations/telegram/index.vue' /* webpackChunkName: "pages/integrations/telegram/index" */))
const _f9068db6 = () => interopDefault(import('../pages/integrations/tokopedia/index.vue' /* webpackChunkName: "pages/integrations/tokopedia/index" */))
const _03a9aca0 = () => interopDefault(import('../pages/integrations/twitter/index.vue' /* webpackChunkName: "pages/integrations/twitter/index" */))
const _7c0b60a9 = () => interopDefault(import('../pages/integrations/webchat/index.vue' /* webpackChunkName: "pages/integrations/webchat/index" */))
const _1eba9aea = () => interopDefault(import('../pages/integrations/whatsapp/index.vue' /* webpackChunkName: "pages/integrations/whatsapp/index" */))
const _57e5a8a2 = () => interopDefault(import('../pages/reports/agent_performance/index.vue' /* webpackChunkName: "pages/reports/agent_performance/index" */))
const _63920f1e = () => interopDefault(import('../pages/reports/bot_performance/index.vue' /* webpackChunkName: "pages/reports/bot_performance/index" */))
const _ba5a028c = () => interopDefault(import('../pages/reports/broadcast/index.vue' /* webpackChunkName: "pages/reports/broadcast/index" */))
const _c58d5e6c = () => interopDefault(import('../pages/reports/ces/index.vue' /* webpackChunkName: "pages/reports/ces/index" */))
const _2e613ed9 = () => interopDefault(import('../pages/reports/contact/index.vue' /* webpackChunkName: "pages/reports/contact/index" */))
const _2bb36d20 = () => interopDefault(import('../pages/reports/csat/index.vue' /* webpackChunkName: "pages/reports/csat/index" */))
const _39b27f7b = () => interopDefault(import('../pages/reports/custom_report/index.vue' /* webpackChunkName: "pages/reports/custom_report/index" */))
const _37c32271 = () => interopDefault(import('../pages/reports/export/index.vue' /* webpackChunkName: "pages/reports/export/index" */))
const _c41b0932 = () => interopDefault(import('../pages/reports/export_conversation/index.vue' /* webpackChunkName: "pages/reports/export_conversation/index" */))
const _f4fc6cbe = () => interopDefault(import('../pages/reports/general/index.vue' /* webpackChunkName: "pages/reports/general/index" */))
const _317cb36a = () => interopDefault(import('../pages/reports/nps/index.vue' /* webpackChunkName: "pages/reports/nps/index" */))
const _1a45a786 = () => interopDefault(import('../pages/settings/accounts_management/index.vue' /* webpackChunkName: "pages/settings/accounts_management/index" */))
const _5ebdd7fa = () => interopDefault(import('../pages/settings/agents/index.vue' /* webpackChunkName: "pages/settings/agents/index" */))
const _60337966 = () => interopDefault(import('../pages/settings/chat/index.vue' /* webpackChunkName: "pages/settings/chat/index" */))
const _50e0b3be = () => interopDefault(import('../pages/settings/contact/index.vue' /* webpackChunkName: "pages/settings/contact/index" */))
const _66af995d = () => interopDefault(import('../pages/settings/import-export/index.vue' /* webpackChunkName: "pages/settings/import-export/index" */))
const _deb048a6 = () => interopDefault(import('../pages/settings/mekari_airene/index.vue' /* webpackChunkName: "pages/settings/mekari_airene/index" */))
const _44ba5b93 = () => interopDefault(import('../pages/settings/score/index.vue' /* webpackChunkName: "pages/settings/score/index" */))
const _475172ba = () => interopDefault(import('../pages/settings/scorecard/index.vue' /* webpackChunkName: "pages/settings/scorecard/index" */))
const _05d16835 = () => interopDefault(import('../pages/settings/security/index.vue' /* webpackChunkName: "pages/settings/security/index" */))
const _2c86c6a2 = () => interopDefault(import('../pages/settings/sla_management/index.vue' /* webpackChunkName: "pages/settings/sla_management/index" */))
const _29c2f4f7 = () => interopDefault(import('../pages/settings/subscriptions/index.vue' /* webpackChunkName: "pages/settings/subscriptions/index" */))
const _573a88fe = () => interopDefault(import('../pages/settings/ticket/index.vue' /* webpackChunkName: "pages/settings/ticket/index" */))
const _52f4023a = () => interopDefault(import('../pages/settings/token/index.vue' /* webpackChunkName: "pages/settings/token/index" */))
const _6ad7a7ad = () => interopDefault(import('../pages/settings/wa_manager/index.vue' /* webpackChunkName: "pages/settings/wa_manager/index" */))
const _5c79339b = () => interopDefault(import('../pages/sign_up/gtm.js' /* webpackChunkName: "pages/sign_up/gtm" */))
const _17dce34d = () => interopDefault(import('../pages/sign_up/msclarity.js' /* webpackChunkName: "pages/sign_up/msclarity" */))
const _0f9be933 = () => interopDefault(import('../pages/sso-callback/switcher.vue' /* webpackChunkName: "pages/sso-callback/switcher" */))
const _1e16aa13 = () => interopDefault(import('../pages/whatsapp/campaigns/index.vue' /* webpackChunkName: "pages/whatsapp/campaigns/index" */))
const _0d717bb9 = () => interopDefault(import('../pages/whatsapp/contacts/index.vue' /* webpackChunkName: "pages/whatsapp/contacts/index" */))
const _a10ed31c = () => interopDefault(import('../pages/whatsapp/hsm/index.vue' /* webpackChunkName: "pages/whatsapp/hsm/index" */))
const _7d967869 = () => interopDefault(import('../pages/whatsapp/templates/index.vue' /* webpackChunkName: "pages/whatsapp/templates/index" */))
const _2df6db21 = () => interopDefault(import('../pages/whatsapp/triggers/index.vue' /* webpackChunkName: "pages/whatsapp/triggers/index" */))
const _1ec66b01 = () => interopDefault(import('../pages/integrations/ecommerce/lazada/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/lazada/index" */))
const _5f2d8bfe = () => interopDefault(import('../pages/integrations/ecommerce/shopee/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/shopee/index" */))
const _6767d856 = () => interopDefault(import('../pages/integrations/ecommerce/tokopedia/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/tokopedia/index" */))
const _d1931ef0 = () => interopDefault(import('../pages/integrations/email/create/index.vue' /* webpackChunkName: "pages/integrations/email/create/index" */))
const _9403d5e4 = () => interopDefault(import('../pages/integrations/facebook_messenger/create/index.vue' /* webpackChunkName: "pages/integrations/facebook_messenger/create/index" */))
const _558f52ad = () => interopDefault(import('../pages/integrations/facebook_messenger/index-old.vue' /* webpackChunkName: "pages/integrations/facebook_messenger/index-old" */))
const _0e0397c0 = () => interopDefault(import('../pages/integrations/line_messenger/create/index.vue' /* webpackChunkName: "pages/integrations/line_messenger/create/index" */))
const _d8d2e2aa = () => interopDefault(import('../pages/integrations/mobilechat/create.vue' /* webpackChunkName: "pages/integrations/mobilechat/create" */))
const _43ad081f = () => interopDefault(import('../pages/integrations/qiscus/create.vue' /* webpackChunkName: "pages/integrations/qiscus/create" */))
const _c82b82ca = () => interopDefault(import('../pages/integrations/qontak_crm/create/index.vue' /* webpackChunkName: "pages/integrations/qontak_crm/create/index" */))
const _e639190e = () => interopDefault(import('../pages/integrations/qontak_crm/new-index.vue' /* webpackChunkName: "pages/integrations/qontak_crm/new-index" */))
const _b76e8980 = () => interopDefault(import('../pages/integrations/qontak_crm/old-index.vue' /* webpackChunkName: "pages/integrations/qontak_crm/old-index" */))
const _463fa52a = () => interopDefault(import('../pages/integrations/qontak_crm/utils.js' /* webpackChunkName: "pages/integrations/qontak_crm/utils" */))
const _6fa39ef9 = () => interopDefault(import('../pages/integrations/qontak_crm/welcome/index.vue' /* webpackChunkName: "pages/integrations/qontak_crm/welcome/index" */))
const _1fae2247 = () => interopDefault(import('../pages/integrations/telegram/create/index.vue' /* webpackChunkName: "pages/integrations/telegram/create/index" */))
const _8a1649de = () => interopDefault(import('../pages/integrations/twitter/create/index.vue' /* webpackChunkName: "pages/integrations/twitter/create/index" */))
const _2d14ff35 = () => interopDefault(import('../pages/integrations/webchat/create.vue' /* webpackChunkName: "pages/integrations/webchat/create" */))
const _4b67e993 = () => interopDefault(import('../pages/integrations/whatsapp/create.vue' /* webpackChunkName: "pages/integrations/whatsapp/create" */))
const _79224fe3 = () => interopDefault(import('../pages/integrations/whatsapp/hsm_logs.vue' /* webpackChunkName: "pages/integrations/whatsapp/hsm_logs" */))
const _8bbeb236 = () => interopDefault(import('../pages/integrations/whatsapp/index-old.vue' /* webpackChunkName: "pages/integrations/whatsapp/index-old" */))
const _494b68a2 = () => interopDefault(import('../pages/integrations/whatsapp/signup.vue' /* webpackChunkName: "pages/integrations/whatsapp/signup" */))
const _dff258da = () => interopDefault(import('../pages/integrations/whatsapp/signup2.vue' /* webpackChunkName: "pages/integrations/whatsapp/signup2" */))
const _78574fd8 = () => interopDefault(import('../pages/reports/agent_performance/constant.js' /* webpackChunkName: "pages/reports/agent_performance/constant" */))
const _6aac911a = () => interopDefault(import('../pages/reports/broadcast/activity/index.vue' /* webpackChunkName: "pages/reports/broadcast/activity/index" */))
const _65a42ef0 = () => interopDefault(import('../pages/reports/broadcast/AdvancedDateFilter.vue' /* webpackChunkName: "pages/reports/broadcast/AdvancedDateFilter" */))
const _a616e7ba = () => interopDefault(import('../pages/reports/broadcast/constant.js' /* webpackChunkName: "pages/reports/broadcast/constant" */))
const _65875acd = () => interopDefault(import('../pages/reports/broadcast/new-index.vue' /* webpackChunkName: "pages/reports/broadcast/new-index" */))
const _7ceca294 = () => interopDefault(import('../pages/reports/broadcast/old-index.vue' /* webpackChunkName: "pages/reports/broadcast/old-index" */))
const _8a7978de = () => interopDefault(import('../pages/reports/broadcast/summary/index.vue' /* webpackChunkName: "pages/reports/broadcast/summary/index" */))
const _b1e2cb9a = () => interopDefault(import('../pages/reports/ces/constant.js' /* webpackChunkName: "pages/reports/ces/constant" */))
const _7f72f909 = () => interopDefault(import('../pages/reports/csat/constant.js' /* webpackChunkName: "pages/reports/csat/constant" */))
const _76f518cc = () => interopDefault(import('../pages/reports/export/chat/index.vue' /* webpackChunkName: "pages/reports/export/chat/index" */))
const _96b48192 = () => interopDefault(import('../pages/reports/export/conversation/index.vue' /* webpackChunkName: "pages/reports/export/conversation/index" */))
const _c1c78d6c = () => interopDefault(import('../pages/reports/general/constant.js' /* webpackChunkName: "pages/reports/general/constant" */))
const _3803d4d3 = () => interopDefault(import('../pages/reports/nps/constant.js' /* webpackChunkName: "pages/reports/nps/constant" */))
const _cb2614ca = () => interopDefault(import('../pages/settings/accounts_management/organization/index.vue' /* webpackChunkName: "pages/settings/accounts_management/organization/index" */))
const _38b8aa94 = () => interopDefault(import('../pages/settings/accounts_management/users/index.vue' /* webpackChunkName: "pages/settings/accounts_management/users/index" */))
const _002a367c = () => interopDefault(import('../pages/settings/agents/allocation/index.vue' /* webpackChunkName: "pages/settings/agents/allocation/index" */))
const _8805ce16 = () => interopDefault(import('../pages/settings/agents/broadcast/index.vue' /* webpackChunkName: "pages/settings/agents/broadcast/index" */))
const _19b90a4b = () => interopDefault(import('../pages/settings/agents/contact_masking/index.vue' /* webpackChunkName: "pages/settings/agents/contact_masking/index" */))
const _56c6ae0f = () => interopDefault(import('../pages/settings/agents/division/index.vue' /* webpackChunkName: "pages/settings/agents/division/index" */))
const _5da5853b = () => interopDefault(import('../pages/settings/agents/idle_rule/index.vue' /* webpackChunkName: "pages/settings/agents/idle_rule/index" */))
const _f9d3df80 = () => interopDefault(import('../pages/settings/agents/list/index.vue' /* webpackChunkName: "pages/settings/agents/list/index" */))
const _2c60a459 = () => interopDefault(import('../pages/settings/agents/workload/index.vue' /* webpackChunkName: "pages/settings/agents/workload/index" */))
const _49e9f374 = () => interopDefault(import('../pages/settings/chat/auto_resolve/index.vue' /* webpackChunkName: "pages/settings/chat/auto_resolve/index" */))
const _230e3a6c = () => interopDefault(import('../pages/settings/chat/auto_responder/index.vue' /* webpackChunkName: "pages/settings/chat/auto_responder/index" */))
const _b0dea838 = () => interopDefault(import('../pages/settings/chat/office_hours/index.vue' /* webpackChunkName: "pages/settings/chat/office_hours/index" */))
const _bacaf61e = () => interopDefault(import('../pages/settings/chat/tags/index.vue' /* webpackChunkName: "pages/settings/chat/tags/index" */))
const _02114f92 = () => interopDefault(import('../pages/settings/chat/templates/index.vue' /* webpackChunkName: "pages/settings/chat/templates/index" */))
const _1c4ddc5c = () => interopDefault(import('../pages/settings/score/ces.vue' /* webpackChunkName: "pages/settings/score/ces" */))
const _12e2e372 = () => interopDefault(import('../pages/settings/score/csat.vue' /* webpackChunkName: "pages/settings/score/csat" */))
const _4a82ec72 = () => interopDefault(import('../pages/settings/score/nps.vue' /* webpackChunkName: "pages/settings/score/nps" */))
const _758b43ef = () => interopDefault(import('../pages/settings/sla_management/create.vue' /* webpackChunkName: "pages/settings/sla_management/create" */))
const _1020c39a = () => interopDefault(import('../pages/settings/token/chatbot/index.vue' /* webpackChunkName: "pages/settings/token/chatbot/index" */))
const _2bd34596 = () => interopDefault(import('../pages/settings/token/omnichannel/index.vue' /* webpackChunkName: "pages/settings/token/omnichannel/index" */))
const _37634972 = () => interopDefault(import('../pages/settings/wa_manager/phone_number/index.vue' /* webpackChunkName: "pages/settings/wa_manager/phone_number/index" */))
const _1d84a614 = () => interopDefault(import('../pages/settings/wa_manager/wa_server/index.vue' /* webpackChunkName: "pages/settings/wa_manager/wa_server/index" */))
const _671a39ea = () => interopDefault(import('../pages/whatsapp/campaigns/create.vue' /* webpackChunkName: "pages/whatsapp/campaigns/create" */))
const _6f1b73b6 = () => interopDefault(import('../pages/whatsapp/contacts/create.vue' /* webpackChunkName: "pages/whatsapp/contacts/create" */))
const _1421da25 = () => interopDefault(import('../pages/whatsapp/contacts/select.vue' /* webpackChunkName: "pages/whatsapp/contacts/select" */))
const _2099f00f = () => interopDefault(import('../pages/whatsapp/hsm/create/index.vue' /* webpackChunkName: "pages/whatsapp/hsm/create/index" */))
const _6ae18b90 = () => interopDefault(import('../pages/whatsapp/templates/create/index.vue' /* webpackChunkName: "pages/whatsapp/templates/create/index" */))
const _6114ac66 = () => interopDefault(import('../pages/integrations/instagram/sections/tab-comments/index.vue' /* webpackChunkName: "pages/integrations/instagram/sections/tab-comments/index" */))
const _42871b66 = () => interopDefault(import('../pages/integrations/instagram/sections/tab-direct-messages/index.vue' /* webpackChunkName: "pages/integrations/instagram/sections/tab-direct-messages/index" */))
const _6cd63236 = () => interopDefault(import('../pages/integrations/qontak_crm/create/account.vue' /* webpackChunkName: "pages/integrations/qontak_crm/create/account" */))
const _7f49c22e = () => interopDefault(import('../pages/integrations/qontak_crm/create/new-index.vue' /* webpackChunkName: "pages/integrations/qontak_crm/create/new-index" */))
const _d2a1ec16 = () => interopDefault(import('../pages/integrations/qontak_crm/create/old-index.vue' /* webpackChunkName: "pages/integrations/qontak_crm/create/old-index" */))
const _69d69f19 = () => interopDefault(import('../pages/integrations/shopee/create/request-integration/index.vue' /* webpackChunkName: "pages/integrations/shopee/create/request-integration/index" */))
const _eb44ad74 = () => interopDefault(import('../pages/integrations/shopee/create/self-integration/index.vue' /* webpackChunkName: "pages/integrations/shopee/create/self-integration/index" */))
const _33c84dc3 = () => interopDefault(import('../pages/integrations/tokopedia/create/request-integration/index.vue' /* webpackChunkName: "pages/integrations/tokopedia/create/request-integration/index" */))
const _50b88adc = () => interopDefault(import('../pages/integrations/tokopedia/create/self-integration/index.vue' /* webpackChunkName: "pages/integrations/tokopedia/create/self-integration/index" */))
const _2a4c6e4c = () => interopDefault(import('../pages/integrations/twitter/create/developer/index.vue' /* webpackChunkName: "pages/integrations/twitter/create/developer/index" */))
const _c92386e4 = () => interopDefault(import('../pages/integrations/twitter/create/failed.vue' /* webpackChunkName: "pages/integrations/twitter/create/failed" */))
const _74726402 = () => interopDefault(import('../pages/integrations/twitter/create/success.vue' /* webpackChunkName: "pages/integrations/twitter/create/success" */))
const _f98ebc8e = () => interopDefault(import('../pages/reports/broadcast/activity/detail.vue' /* webpackChunkName: "pages/reports/broadcast/activity/detail" */))
const _05270a36 = () => interopDefault(import('../pages/reports/broadcast/summary/ListOfCampaign.vue' /* webpackChunkName: "pages/reports/broadcast/summary/ListOfCampaign" */))
const _012bb7c8 = () => interopDefault(import('../pages/reports/broadcast/summary/StatusCount.vue' /* webpackChunkName: "pages/reports/broadcast/summary/StatusCount" */))
const _2275eb58 = () => interopDefault(import('../pages/reports/broadcast/summary/TotalMessage.vue' /* webpackChunkName: "pages/reports/broadcast/summary/TotalMessage" */))
const _35ac7dd2 = () => interopDefault(import('../pages/settings/agents/division/create/index.vue' /* webpackChunkName: "pages/settings/agents/division/create/index" */))
const _0a416287 = () => interopDefault(import('../pages/settings/chat/auto_resolve/new-index.vue' /* webpackChunkName: "pages/settings/chat/auto_resolve/new-index" */))
const _21a6aa4e = () => interopDefault(import('../pages/settings/chat/auto_resolve/old-index.vue' /* webpackChunkName: "pages/settings/chat/auto_resolve/old-index" */))
const _07a7f967 = () => interopDefault(import('../pages/settings/chat/templates/create.vue' /* webpackChunkName: "pages/settings/chat/templates/create" */))
const _506d6835 = () => interopDefault(import('../pages/settings/chat/templates/edit.vue' /* webpackChunkName: "pages/settings/chat/templates/edit" */))
const _1a68bf8f = () => interopDefault(import('../pages/whatsapp/hsm/create/v2.vue' /* webpackChunkName: "pages/whatsapp/hsm/create/v2" */))
const _0246ce06 = () => interopDefault(import('../pages/whatsapp/templates/create/v2.vue' /* webpackChunkName: "pages/whatsapp/templates/create/v2" */))
const _23537b4a = () => interopDefault(import('../pages/integrations/ecommerce/shopee/create/request-integration/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/shopee/create/request-integration/index" */))
const _6ef6ba96 = () => interopDefault(import('../pages/integrations/ecommerce/shopee/create/self-integration/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/shopee/create/self-integration/index" */))
const _c637901c = () => interopDefault(import('../pages/integrations/ecommerce/tokopedia/create/request-integration/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/tokopedia/create/request-integration/index" */))
const _0a35670d = () => interopDefault(import('../pages/integrations/ecommerce/tokopedia/create/self-integration/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/tokopedia/create/self-integration/index" */))
const _3d52d9e9 = () => interopDefault(import('../pages/integrations/shopee/create/request-integration/welcome.vue' /* webpackChunkName: "pages/integrations/shopee/create/request-integration/welcome" */))
const _58400cd6 = () => interopDefault(import('../pages/integrations/shopee/create/self-integration/welcome.vue' /* webpackChunkName: "pages/integrations/shopee/create/self-integration/welcome" */))
const _e9ed244c = () => interopDefault(import('../pages/integrations/ecommerce/shopee/create/request-integration/welcome.vue' /* webpackChunkName: "pages/integrations/ecommerce/shopee/create/request-integration/welcome" */))
const _aee332f6 = () => interopDefault(import('../pages/integrations/ecommerce/shopee/create/self-integration/welcome.vue' /* webpackChunkName: "pages/integrations/ecommerce/shopee/create/self-integration/welcome" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _915d76b8 = () => interopDefault(import('../pages/integrations/ecommerce/tokopedia/edit/_id/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/tokopedia/edit/_id/index" */))
const _2c4477e9 = () => interopDefault(import('../pages/integrations/ecommerce/shopee/_id/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/shopee/_id/index" */))
const _895e4f7e = () => interopDefault(import('../pages/integrations/ecommerce/tokopedia/_id/index.vue' /* webpackChunkName: "pages/integrations/ecommerce/tokopedia/_id/index" */))
const _44e047c6 = () => interopDefault(import('../pages/integrations/facebook_messenger/reconnect/_id/index.vue' /* webpackChunkName: "pages/integrations/facebook_messenger/reconnect/_id/index" */))
const _87ca4696 = () => interopDefault(import('../pages/integrations/tokopedia/edit/_id/index.vue' /* webpackChunkName: "pages/integrations/tokopedia/edit/_id/index" */))
const _ff6869f4 = () => interopDefault(import('../pages/settings/accounts_management/organization/_id/index.vue' /* webpackChunkName: "pages/settings/accounts_management/organization/_id/index" */))
const _369c7aa1 = () => interopDefault(import('../pages/settings/accounts_management/users/_id/index.vue' /* webpackChunkName: "pages/settings/accounts_management/users/_id/index" */))
const _63ed0b2b = () => interopDefault(import('../pages/settings/agents/list/_id/index.vue' /* webpackChunkName: "pages/settings/agents/list/_id/index" */))
const _d7689054 = () => interopDefault(import('../pages/settings/sla_management/duplicate/_id/index.vue' /* webpackChunkName: "pages/settings/sla_management/duplicate/_id/index" */))
const _88bd572a = () => interopDefault(import('../pages/settings/sla_management/edit/_id/index.vue' /* webpackChunkName: "pages/settings/sla_management/edit/_id/index" */))
const _f18fbaf8 = () => interopDefault(import('../pages/integrations/email/_id/index.vue' /* webpackChunkName: "pages/integrations/email/_id/index" */))
const _942f8884 = () => interopDefault(import('../pages/integrations/facebook_messenger/_id/index.vue' /* webpackChunkName: "pages/integrations/facebook_messenger/_id/index" */))
const _c08e7428 = () => interopDefault(import('../pages/integrations/line_messenger/_id/index.vue' /* webpackChunkName: "pages/integrations/line_messenger/_id/index" */))
const _0697b3de = () => interopDefault(import('../pages/integrations/mobilechat/_id/index.vue' /* webpackChunkName: "pages/integrations/mobilechat/_id/index" */))
const _e4d3755e = () => interopDefault(import('../pages/integrations/qontak_crm/_id/index.vue' /* webpackChunkName: "pages/integrations/qontak_crm/_id/index" */))
const _da689e0c = () => interopDefault(import('../pages/integrations/shopee/_id/index.vue' /* webpackChunkName: "pages/integrations/shopee/_id/index" */))
const _2768e125 = () => interopDefault(import('../pages/integrations/telegram/_id/index.vue' /* webpackChunkName: "pages/integrations/telegram/_id/index" */))
const _0d5ab990 = () => interopDefault(import('../pages/integrations/tokopedia/_id/index.vue' /* webpackChunkName: "pages/integrations/tokopedia/_id/index" */))
const _4781ff14 = () => interopDefault(import('../pages/integrations/webchat/_id/index.vue' /* webpackChunkName: "pages/integrations/webchat/_id/index" */))
const _1a798ff6 = () => interopDefault(import('../pages/integrations/whatsapp/_id/index.vue' /* webpackChunkName: "pages/integrations/whatsapp/_id/index" */))
const _6b9abc62 = () => interopDefault(import('../pages/settings/subscriptions/_id/index.vue' /* webpackChunkName: "pages/settings/subscriptions/_id/index" */))
const _12d2f904 = () => interopDefault(import('../pages/whatsapp/campaigns/_id/index.vue' /* webpackChunkName: "pages/whatsapp/campaigns/_id/index" */))
const _33441224 = () => interopDefault(import('../pages/whatsapp/contacts/_id/index.vue' /* webpackChunkName: "pages/whatsapp/contacts/_id/index" */))
const _1b45af46 = () => interopDefault(import('../pages/whatsapp/hsm/_id/index.vue' /* webpackChunkName: "pages/whatsapp/hsm/_id/index" */))
const _4b899258 = () => interopDefault(import('../pages/whatsapp/templates/_id/index.vue' /* webpackChunkName: "pages/whatsapp/templates/_id/index" */))
const _6bbc586a = () => interopDefault(import('../pages/integrations/qiscus/_id/indexxx.vue' /* webpackChunkName: "pages/integrations/qiscus/_id/indexxx" */))
const _3924d6d0 = () => interopDefault(import('../pages/integrations/whatsapp/_id/index-old.vue' /* webpackChunkName: "pages/integrations/whatsapp/_id/index-old" */))
const _49db3ca8 = () => interopDefault(import('../pages/whatsapp/contacts/_id/edit.vue' /* webpackChunkName: "pages/whatsapp/contacts/_id/edit" */))
const _807ba32a = () => interopDefault(import('../pages/contacts/_id/index.vue' /* webpackChunkName: "pages/contacts/_id/index" */))
const _14a50d04 = () => interopDefault(import('../pages/survey/_code/index.vue' /* webpackChunkName: "pages/survey/_code/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/campaign",
    component: _2bca6bfd,
    name: "campaign"
  }, {
    path: "/chatbot",
    component: _a64e4c90,
    name: "chatbot"
  }, {
    path: "/contacts",
    component: _d9025900,
    name: "contacts"
  }, {
    path: "/contacts-upload-multiple",
    component: _67558f4f,
    name: "contacts-upload-multiple"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/direct_login",
    component: _372bc5c0,
    name: "direct_login"
  }, {
    path: "/finances",
    component: _56caef86,
    name: "finances"
  }, {
    path: "/forgot_password",
    component: _62708680,
    name: "forgot_password"
  }, {
    path: "/inbox",
    component: _01ade38f,
    name: "inbox"
  }, {
    path: "/integrations",
    component: _20d0f04c,
    name: "integrations"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/outlookcallback",
    component: _97d2aa8a,
    name: "outlookcallback"
  }, {
    path: "/reports",
    component: _16617c08,
    name: "reports"
  }, {
    path: "/reset_password",
    component: _24927f38,
    name: "reset_password"
  }, {
    path: "/resources",
    component: _72065d6e,
    name: "resources"
  }, {
    path: "/settings",
    component: _2c20a960,
    name: "settings"
  }, {
    path: "/shopeecallback",
    component: _203e1008,
    name: "shopeecallback"
  }, {
    path: "/sign_up",
    component: _78085d26,
    name: "sign_up"
  }, {
    path: "/sso-callback",
    component: _5e6fd470,
    name: "sso-callback"
  }, {
    path: "/unprivileged",
    component: _517f8219,
    name: "unprivileged"
  }, {
    path: "/whatsapp",
    component: _6cf499ff,
    name: "whatsapp"
  }, {
    path: "/finances/ask_airene",
    component: _392eae57,
    name: "finances-ask_airene"
  }, {
    path: "/finances/billable_usages",
    component: _a9172878,
    name: "finances-billable_usages"
  }, {
    path: "/finances/billing_usages.copy",
    component: _2954d961,
    name: "finances-billing_usages.copy"
  }, {
    path: "/finances/chatbot_usage",
    component: _433edec8,
    name: "finances-chatbot_usage"
  }, {
    path: "/finances/credits",
    component: _a4b4ff64,
    name: "finances-credits"
  }, {
    path: "/finances/invoices",
    component: _3c1e803c,
    name: "finances-invoices"
  }, {
    path: "/finances/orders",
    component: _f2681d3e,
    name: "finances-orders"
  }, {
    path: "/finances/package_usage",
    component: _342e781c,
    name: "finances-package_usage"
  }, {
    path: "/finances/payments",
    component: _07099eae,
    name: "finances-payments"
  }, {
    path: "/finances/subscribe_plan",
    component: _07ed4a0c,
    name: "finances-subscribe_plan"
  }, {
    path: "/finances/topup",
    component: _99771338,
    name: "finances-topup"
  }, {
    path: "/integrations/ecommerce",
    component: _3ba1ae86,
    name: "integrations-ecommerce"
  }, {
    path: "/integrations/email",
    component: _188687ce,
    name: "integrations-email"
  }, {
    path: "/integrations/facebook_messenger",
    component: _ece2fb5a,
    name: "integrations-facebook_messenger"
  }, {
    path: "/integrations/instagram",
    component: _1df6d3af,
    name: "integrations-instagram"
  }, {
    path: "/integrations/line_messenger",
    component: _88b158fe,
    name: "integrations-line_messenger"
  }, {
    path: "/integrations/livechatcom",
    component: _3156b30c,
    name: "integrations-livechatcom"
  }, {
    path: "/integrations/mobilechat",
    component: _13ff0a73,
    name: "integrations-mobilechat"
  }, {
    path: "/integrations/qiscus",
    component: _4b39927f,
    name: "integrations-qiscus"
  }, {
    path: "/integrations/qontak_crm",
    component: _0b0e4d66,
    name: "integrations-qontak_crm"
  }, {
    path: "/integrations/shopee",
    component: _abd414e2,
    name: "integrations-shopee"
  }, {
    path: "/integrations/telegram",
    component: _2c8ff33a,
    name: "integrations-telegram"
  }, {
    path: "/integrations/tokopedia",
    component: _f9068db6,
    name: "integrations-tokopedia"
  }, {
    path: "/integrations/twitter",
    component: _03a9aca0,
    name: "integrations-twitter"
  }, {
    path: "/integrations/webchat",
    component: _7c0b60a9,
    name: "integrations-webchat"
  }, {
    path: "/integrations/whatsapp",
    component: _1eba9aea,
    name: "integrations-whatsapp"
  }, {
    path: "/reports/agent_performance",
    component: _57e5a8a2,
    name: "reports-agent_performance"
  }, {
    path: "/reports/bot_performance",
    component: _63920f1e,
    name: "reports-bot_performance"
  }, {
    path: "/reports/broadcast",
    component: _ba5a028c,
    name: "reports-broadcast"
  }, {
    path: "/reports/ces",
    component: _c58d5e6c,
    name: "reports-ces"
  }, {
    path: "/reports/contact",
    component: _2e613ed9,
    name: "reports-contact"
  }, {
    path: "/reports/csat",
    component: _2bb36d20,
    name: "reports-csat"
  }, {
    path: "/reports/custom_report",
    component: _39b27f7b,
    name: "reports-custom_report"
  }, {
    path: "/reports/export",
    component: _37c32271,
    name: "reports-export"
  }, {
    path: "/reports/export_conversation",
    component: _c41b0932,
    name: "reports-export_conversation"
  }, {
    path: "/reports/general",
    component: _f4fc6cbe,
    name: "reports-general"
  }, {
    path: "/reports/nps",
    component: _317cb36a,
    name: "reports-nps"
  }, {
    path: "/settings/accounts_management",
    component: _1a45a786,
    name: "settings-accounts_management"
  }, {
    path: "/settings/agents",
    component: _5ebdd7fa,
    name: "settings-agents"
  }, {
    path: "/settings/chat",
    component: _60337966,
    name: "settings-chat"
  }, {
    path: "/settings/contact",
    component: _50e0b3be,
    name: "settings-contact"
  }, {
    path: "/settings/import-export",
    component: _66af995d,
    name: "settings-import-export"
  }, {
    path: "/settings/mekari_airene",
    component: _deb048a6,
    name: "settings-mekari_airene"
  }, {
    path: "/settings/score",
    component: _44ba5b93,
    name: "settings-score"
  }, {
    path: "/settings/scorecard",
    component: _475172ba,
    name: "settings-scorecard"
  }, {
    path: "/settings/security",
    component: _05d16835,
    name: "settings-security"
  }, {
    path: "/settings/sla_management",
    component: _2c86c6a2,
    name: "settings-sla_management"
  }, {
    path: "/settings/subscriptions",
    component: _29c2f4f7,
    name: "settings-subscriptions"
  }, {
    path: "/settings/ticket",
    component: _573a88fe,
    name: "settings-ticket"
  }, {
    path: "/settings/token",
    component: _52f4023a,
    name: "settings-token"
  }, {
    path: "/settings/wa_manager",
    component: _6ad7a7ad,
    name: "settings-wa_manager"
  }, {
    path: "/sign_up/gtm",
    component: _5c79339b,
    name: "sign_up-gtm"
  }, {
    path: "/sign_up/msclarity",
    component: _17dce34d,
    name: "sign_up-msclarity"
  }, {
    path: "/sso-callback/switcher",
    component: _0f9be933,
    name: "sso-callback-switcher"
  }, {
    path: "/whatsapp/campaigns",
    component: _1e16aa13,
    name: "whatsapp-campaigns"
  }, {
    path: "/whatsapp/contacts",
    component: _0d717bb9,
    name: "whatsapp-contacts"
  }, {
    path: "/whatsapp/hsm",
    component: _a10ed31c,
    name: "whatsapp-hsm"
  }, {
    path: "/whatsapp/templates",
    component: _7d967869,
    name: "whatsapp-templates"
  }, {
    path: "/whatsapp/triggers",
    component: _2df6db21,
    name: "whatsapp-triggers"
  }, {
    path: "/integrations/ecommerce/lazada",
    component: _1ec66b01,
    name: "integrations-ecommerce-lazada"
  }, {
    path: "/integrations/ecommerce/shopee",
    component: _5f2d8bfe,
    name: "integrations-ecommerce-shopee"
  }, {
    path: "/integrations/ecommerce/tokopedia",
    component: _6767d856,
    name: "integrations-ecommerce-tokopedia"
  }, {
    path: "/integrations/email/create",
    component: _d1931ef0,
    name: "integrations-email-create"
  }, {
    path: "/integrations/facebook_messenger/create",
    component: _9403d5e4,
    name: "integrations-facebook_messenger-create"
  }, {
    path: "/integrations/facebook_messenger/index-old",
    component: _558f52ad,
    name: "integrations-facebook_messenger-index-old"
  }, {
    path: "/integrations/line_messenger/create",
    component: _0e0397c0,
    name: "integrations-line_messenger-create"
  }, {
    path: "/integrations/mobilechat/create",
    component: _d8d2e2aa,
    name: "integrations-mobilechat-create"
  }, {
    path: "/integrations/qiscus/create",
    component: _43ad081f,
    name: "integrations-qiscus-create"
  }, {
    path: "/integrations/qontak_crm/create",
    component: _c82b82ca,
    name: "integrations-qontak_crm-create"
  }, {
    path: "/integrations/qontak_crm/new-index",
    component: _e639190e,
    name: "integrations-qontak_crm-new"
  }, {
    path: "/integrations/qontak_crm/old-index",
    component: _b76e8980,
    name: "integrations-qontak_crm-old"
  }, {
    path: "/integrations/qontak_crm/utils",
    component: _463fa52a,
    name: "integrations-qontak_crm-utils"
  }, {
    path: "/integrations/qontak_crm/welcome",
    component: _6fa39ef9,
    name: "integrations-qontak_crm-welcome"
  }, {
    path: "/integrations/telegram/create",
    component: _1fae2247,
    name: "integrations-telegram-create"
  }, {
    path: "/integrations/twitter/create",
    component: _8a1649de,
    name: "integrations-twitter-create"
  }, {
    path: "/integrations/webchat/create",
    component: _2d14ff35,
    name: "integrations-webchat-create"
  }, {
    path: "/integrations/whatsapp/create",
    component: _4b67e993,
    name: "integrations-whatsapp-create"
  }, {
    path: "/integrations/whatsapp/hsm_logs",
    component: _79224fe3,
    name: "integrations-whatsapp-hsm_logs"
  }, {
    path: "/integrations/whatsapp/index-old",
    component: _8bbeb236,
    name: "integrations-whatsapp-index-old"
  }, {
    path: "/integrations/whatsapp/signup",
    component: _494b68a2,
    name: "integrations-whatsapp-signup"
  }, {
    path: "/integrations/whatsapp/signup2",
    component: _dff258da,
    name: "integrations-whatsapp-signup2"
  }, {
    path: "/reports/agent_performance/constant",
    component: _78574fd8,
    name: "reports-agent_performance-constant"
  }, {
    path: "/reports/broadcast/activity",
    component: _6aac911a,
    name: "reports-broadcast-activity"
  }, {
    path: "/reports/broadcast/AdvancedDateFilter",
    component: _65a42ef0,
    name: "reports-broadcast-AdvancedDateFilter"
  }, {
    path: "/reports/broadcast/constant",
    component: _a616e7ba,
    name: "reports-broadcast-constant"
  }, {
    path: "/reports/broadcast/new-index",
    component: _65875acd,
    name: "reports-broadcast-new"
  }, {
    path: "/reports/broadcast/old-index",
    component: _7ceca294,
    name: "reports-broadcast-old"
  }, {
    path: "/reports/broadcast/summary",
    component: _8a7978de,
    name: "reports-broadcast-summary"
  }, {
    path: "/reports/ces/constant",
    component: _b1e2cb9a,
    name: "reports-ces-constant"
  }, {
    path: "/reports/csat/constant",
    component: _7f72f909,
    name: "reports-csat-constant"
  }, {
    path: "/reports/export/chat",
    component: _76f518cc,
    name: "reports-export-chat"
  }, {
    path: "/reports/export/conversation",
    component: _96b48192,
    name: "reports-export-conversation"
  }, {
    path: "/reports/general/constant",
    component: _c1c78d6c,
    name: "reports-general-constant"
  }, {
    path: "/reports/nps/constant",
    component: _3803d4d3,
    name: "reports-nps-constant"
  }, {
    path: "/settings/accounts_management/organization",
    component: _cb2614ca,
    name: "settings-accounts_management-organization"
  }, {
    path: "/settings/accounts_management/users",
    component: _38b8aa94,
    name: "settings-accounts_management-users"
  }, {
    path: "/settings/agents/allocation",
    component: _002a367c,
    name: "settings-agents-allocation"
  }, {
    path: "/settings/agents/broadcast",
    component: _8805ce16,
    name: "settings-agents-broadcast"
  }, {
    path: "/settings/agents/contact_masking",
    component: _19b90a4b,
    name: "settings-agents-contact_masking"
  }, {
    path: "/settings/agents/division",
    component: _56c6ae0f,
    name: "settings-agents-division"
  }, {
    path: "/settings/agents/idle_rule",
    component: _5da5853b,
    name: "settings-agents-idle_rule"
  }, {
    path: "/settings/agents/list",
    component: _f9d3df80,
    name: "settings-agents-list"
  }, {
    path: "/settings/agents/workload",
    component: _2c60a459,
    name: "settings-agents-workload"
  }, {
    path: "/settings/chat/auto_resolve",
    component: _49e9f374,
    name: "settings-chat-auto_resolve"
  }, {
    path: "/settings/chat/auto_responder",
    component: _230e3a6c,
    name: "settings-chat-auto_responder"
  }, {
    path: "/settings/chat/office_hours",
    component: _b0dea838,
    name: "settings-chat-office_hours"
  }, {
    path: "/settings/chat/tags",
    component: _bacaf61e,
    name: "settings-chat-tags"
  }, {
    path: "/settings/chat/templates",
    component: _02114f92,
    name: "settings-chat-templates"
  }, {
    path: "/settings/score/ces",
    component: _1c4ddc5c,
    name: "settings-score-ces"
  }, {
    path: "/settings/score/csat",
    component: _12e2e372,
    name: "settings-score-csat"
  }, {
    path: "/settings/score/nps",
    component: _4a82ec72,
    name: "settings-score-nps"
  }, {
    path: "/settings/sla_management/create",
    component: _758b43ef,
    name: "settings-sla_management-create"
  }, {
    path: "/settings/token/chatbot",
    component: _1020c39a,
    name: "settings-token-chatbot"
  }, {
    path: "/settings/token/omnichannel",
    component: _2bd34596,
    name: "settings-token-omnichannel"
  }, {
    path: "/settings/wa_manager/phone_number",
    component: _37634972,
    name: "settings-wa_manager-phone_number"
  }, {
    path: "/settings/wa_manager/wa_server",
    component: _1d84a614,
    name: "settings-wa_manager-wa_server"
  }, {
    path: "/whatsapp/campaigns/create",
    component: _671a39ea,
    name: "whatsapp-campaigns-create"
  }, {
    path: "/whatsapp/contacts/create",
    component: _6f1b73b6,
    name: "whatsapp-contacts-create"
  }, {
    path: "/whatsapp/contacts/select",
    component: _1421da25,
    name: "whatsapp-contacts-select"
  }, {
    path: "/whatsapp/hsm/create",
    component: _2099f00f,
    name: "whatsapp-hsm-create"
  }, {
    path: "/whatsapp/templates/create",
    component: _6ae18b90,
    name: "whatsapp-templates-create"
  }, {
    path: "/integrations/instagram/sections/tab-comments",
    component: _6114ac66,
    name: "integrations-instagram-sections-tab-comments"
  }, {
    path: "/integrations/instagram/sections/tab-direct-messages",
    component: _42871b66,
    name: "integrations-instagram-sections-tab-direct-messages"
  }, {
    path: "/integrations/qontak_crm/create/account",
    component: _6cd63236,
    name: "integrations-qontak_crm-create-account"
  }, {
    path: "/integrations/qontak_crm/create/new-index",
    component: _7f49c22e,
    name: "integrations-qontak_crm-create-new"
  }, {
    path: "/integrations/qontak_crm/create/old-index",
    component: _d2a1ec16,
    name: "integrations-qontak_crm-create-old"
  }, {
    path: "/integrations/shopee/create/request-integration",
    component: _69d69f19,
    name: "integrations-shopee-create-request-integration"
  }, {
    path: "/integrations/shopee/create/self-integration",
    component: _eb44ad74,
    name: "integrations-shopee-create-self-integration"
  }, {
    path: "/integrations/tokopedia/create/request-integration",
    component: _33c84dc3,
    name: "integrations-tokopedia-create-request-integration"
  }, {
    path: "/integrations/tokopedia/create/self-integration",
    component: _50b88adc,
    name: "integrations-tokopedia-create-self-integration"
  }, {
    path: "/integrations/twitter/create/developer",
    component: _2a4c6e4c,
    name: "integrations-twitter-create-developer"
  }, {
    path: "/integrations/twitter/create/failed",
    component: _c92386e4,
    name: "integrations-twitter-create-failed"
  }, {
    path: "/integrations/twitter/create/success",
    component: _74726402,
    name: "integrations-twitter-create-success"
  }, {
    path: "/reports/broadcast/activity/detail",
    component: _f98ebc8e,
    name: "reports-broadcast-activity-detail"
  }, {
    path: "/reports/broadcast/summary/ListOfCampaign",
    component: _05270a36,
    name: "reports-broadcast-summary-ListOfCampaign"
  }, {
    path: "/reports/broadcast/summary/StatusCount",
    component: _012bb7c8,
    name: "reports-broadcast-summary-StatusCount"
  }, {
    path: "/reports/broadcast/summary/TotalMessage",
    component: _2275eb58,
    name: "reports-broadcast-summary-TotalMessage"
  }, {
    path: "/settings/agents/division/create",
    component: _35ac7dd2,
    name: "settings-agents-division-create"
  }, {
    path: "/settings/chat/auto_resolve/new-index",
    component: _0a416287,
    name: "settings-chat-auto_resolve-new"
  }, {
    path: "/settings/chat/auto_resolve/old-index",
    component: _21a6aa4e,
    name: "settings-chat-auto_resolve-old"
  }, {
    path: "/settings/chat/templates/create",
    component: _07a7f967,
    name: "settings-chat-templates-create"
  }, {
    path: "/settings/chat/templates/edit",
    component: _506d6835,
    name: "settings-chat-templates-edit"
  }, {
    path: "/whatsapp/hsm/create/v2",
    component: _1a68bf8f,
    name: "whatsapp-hsm-create-v2"
  }, {
    path: "/whatsapp/templates/create/v2",
    component: _0246ce06,
    name: "whatsapp-templates-create-v2"
  }, {
    path: "/integrations/ecommerce/shopee/create/request-integration",
    component: _23537b4a,
    name: "integrations-ecommerce-shopee-create-request-integration"
  }, {
    path: "/integrations/ecommerce/shopee/create/self-integration",
    component: _6ef6ba96,
    name: "integrations-ecommerce-shopee-create-self-integration"
  }, {
    path: "/integrations/ecommerce/tokopedia/create/request-integration",
    component: _c637901c,
    name: "integrations-ecommerce-tokopedia-create-request-integration"
  }, {
    path: "/integrations/ecommerce/tokopedia/create/self-integration",
    component: _0a35670d,
    name: "integrations-ecommerce-tokopedia-create-self-integration"
  }, {
    path: "/integrations/shopee/create/request-integration/welcome",
    component: _3d52d9e9,
    name: "integrations-shopee-create-request-integration-welcome"
  }, {
    path: "/integrations/shopee/create/self-integration/welcome",
    component: _58400cd6,
    name: "integrations-shopee-create-self-integration-welcome"
  }, {
    path: "/integrations/ecommerce/shopee/create/request-integration/welcome",
    component: _e9ed244c,
    name: "integrations-ecommerce-shopee-create-request-integration-welcome"
  }, {
    path: "/integrations/ecommerce/shopee/create/self-integration/welcome",
    component: _aee332f6,
    name: "integrations-ecommerce-shopee-create-self-integration-welcome"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/integrations/ecommerce/tokopedia/edit/:id",
    component: _915d76b8,
    name: "integrations-ecommerce-tokopedia-edit-id"
  }, {
    path: "/integrations/ecommerce/shopee/:id",
    component: _2c4477e9,
    name: "integrations-ecommerce-shopee-id"
  }, {
    path: "/integrations/ecommerce/tokopedia/:id",
    component: _895e4f7e,
    name: "integrations-ecommerce-tokopedia-id"
  }, {
    path: "/integrations/facebook_messenger/reconnect/:id",
    component: _44e047c6,
    name: "integrations-facebook_messenger-reconnect-id"
  }, {
    path: "/integrations/tokopedia/edit/:id",
    component: _87ca4696,
    name: "integrations-tokopedia-edit-id"
  }, {
    path: "/settings/accounts_management/organization/:id",
    component: _ff6869f4,
    name: "settings-accounts_management-organization-id"
  }, {
    path: "/settings/accounts_management/users/:id",
    component: _369c7aa1,
    name: "settings-accounts_management-users-id"
  }, {
    path: "/settings/agents/list/:id",
    component: _63ed0b2b,
    name: "settings-agents-list-id"
  }, {
    path: "/settings/sla_management/duplicate/:id",
    component: _d7689054,
    name: "settings-sla_management-duplicate-id"
  }, {
    path: "/settings/sla_management/edit/:id",
    component: _88bd572a,
    name: "settings-sla_management-edit-id"
  }, {
    path: "/integrations/email/:id",
    component: _f18fbaf8,
    name: "integrations-email-id"
  }, {
    path: "/integrations/facebook_messenger/:id",
    component: _942f8884,
    name: "integrations-facebook_messenger-id"
  }, {
    path: "/integrations/line_messenger/:id",
    component: _c08e7428,
    name: "integrations-line_messenger-id"
  }, {
    path: "/integrations/mobilechat/:id",
    component: _0697b3de,
    name: "integrations-mobilechat-id"
  }, {
    path: "/integrations/qontak_crm/:id",
    component: _e4d3755e,
    name: "integrations-qontak_crm-id"
  }, {
    path: "/integrations/shopee/:id",
    component: _da689e0c,
    name: "integrations-shopee-id"
  }, {
    path: "/integrations/telegram/:id",
    component: _2768e125,
    name: "integrations-telegram-id"
  }, {
    path: "/integrations/tokopedia/:id",
    component: _0d5ab990,
    name: "integrations-tokopedia-id"
  }, {
    path: "/integrations/webchat/:id",
    component: _4781ff14,
    name: "integrations-webchat-id"
  }, {
    path: "/integrations/whatsapp/:id",
    component: _1a798ff6,
    name: "integrations-whatsapp-id"
  }, {
    path: "/settings/subscriptions/:id",
    component: _6b9abc62,
    name: "settings-subscriptions-id"
  }, {
    path: "/whatsapp/campaigns/:id",
    component: _12d2f904,
    name: "whatsapp-campaigns-id"
  }, {
    path: "/whatsapp/contacts/:id",
    component: _33441224,
    name: "whatsapp-contacts-id"
  }, {
    path: "/whatsapp/hsm/:id",
    component: _1b45af46,
    name: "whatsapp-hsm-id"
  }, {
    path: "/whatsapp/templates/:id",
    component: _4b899258,
    name: "whatsapp-templates-id"
  }, {
    path: "/integrations/qiscus/:id/indexxx",
    component: _6bbc586a,
    name: "integrations-qiscus-id-indexxx"
  }, {
    path: "/integrations/whatsapp/:id/index-old",
    component: _3924d6d0,
    name: "integrations-whatsapp-id-index-old"
  }, {
    path: "/whatsapp/contacts/:id/edit",
    component: _49db3ca8,
    name: "whatsapp-contacts-id-edit"
  }, {
    path: "/contacts/:id",
    component: _807ba32a,
    name: "contacts-id"
  }, {
    path: "/survey/:code",
    component: _14a50d04,
    name: "survey-code"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
